<template>
    <!-- 用户风控管理 userRiskManagement -->
    <div class="container">
        <div class="header" v-if="isMobile==0">
            <router-link class="btn" to="/stocks">{{$t('aside.list')[1]}}</router-link>
            <i class="iconfont2 icon-right_arrow"></i>
            <span>{{$t('stocks.userRiskManagement')}}</span>
        </div>

        <div class="content" :class="{content_phone:isMobile==1}">
            <div class="title">
                <i class="iconfont2 icon-shaixuan"></i>
                {{$t('subManage.groupMange')}}
            </div>
            <div class="searchBox">
                <el-input
                    :placeholder="$t('stocks.searchUserName')"
                    prefix-icon="el-icon-search"
                    class="inp"
                    v-model="userName"
                    @keyup.enter.native="searchUser">
                </el-input>
                <div class="g_btn addBtn" @click="openAddDialog">
                    <i class="iconfont2 icon-roundadd"></i>
                    {{$t('stocks.addUser')}}
                </div>
            </div>

            <div class="title">
                <i class="iconfont2 icon-shaixuan"></i>
                {{$t('stocks.userList')}}
            </div>
            <div class="tableBox">
                <el-table
                    v-loading="loading"
                    height="100%"
                    :data="userList">
                    <el-table-column
                    prop="user_id"
                    :label="$t('common.userName')"
                    min-width="100">
                    </el-table-column>

                    <el-table-column
                    :label="$t('common.operation')"
                    min-width="100">
                        <template slot-scope="scope">
                            <div class="g_btn delBtn" @click="handleDelete(scope.row)">
                                {{$t('common.del')}}
                            </div>
                            <div class="g_btn viewBtn" @click="handleView(scope.row)">
                                {{$t('user.viewRecords')}}
                            </div>
                        </template>
                    </el-table-column>
                    
                    <el-empty slot="empty"
                    :image="emptyImg"
                    :image-size="120"
                    :description="$t('common.noData')"
                    />
                </el-table>
            </div>
            <div class="pagination">
                <el-pagination
                  background
                  layout="sizes, prev, pager, next"
                  :total="total"
                  :page-size="pageSize"
                  :page-sizes="[20, 50, 100]"
                  :current-page="currentPage"
                  @current-change="handleCurrentChange"
                  @size-change="handleSizeChange"
                >
                </el-pagination>
            </div>
        </div>

        <!-- 新增用户弹窗 -->
        <el-dialog :title="$t('stocks.addUser')" :visible.sync="showDialog">
            <div class="dialogFormBox">
                <div class="item">
                    <div class="title">{{$t('common.exchange')}}</div>
                    <div class="text">
                        <div class="select" @click="showExchangeDialog = true">
                            <input v-model="popExchange" type="text" :placeholder="$t('common.select')" readonly class="inp" />
                            <i class="iconfont el-icon-arrow-down"></i>
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="title">{{$t('common.userName')}}</div>
                    <div class="text">
                        <input
                            class="inp"
                            :placeholder="$t('tip.usernameEmpty')"
                            type="text"
                            v-model="user_id"
                        />
                    </div>
                </div>
                <div class="g_dialogBtns">
                    <div class="g_btn btn" @click="showDialog=false">{{$t('common.cancel')}}</div>
                    <div class="g_btn btn submit" @click="addUser">{{$t('common.confirm2')}}</div>
                </div>
            </div>
        </el-dialog>
		<!-- 交易所选择 -->
		<exchange-dialog
			:show.sync="showExchangeDialog"
			:web="popWeb"
			@close="closeExchange"
			@select="handleSelect"
		/>
    </div>
</template>
<style lang="stylus" scoped>
@import 'userRiskManagement.styl';
</style>
<script>
import { mapGetters } from 'vuex';

import { Loading } from 'element-ui';
import ExchangeDialog from '@/components/exchange.vue';
// api
import {
  riskUserList,
  createRiskUser,
  removeRiskUser,
} from "@/api/markets";

import { 
    getWebInfo,
    setWebInfo,
} from '@/utils/methApi/tradingPairDefault'
export default {
    components: {
        ExchangeDialog,
    },
    computed:{
        ...mapGetters(['account','isMobile']),
    },
    data() {
        return {
            emptyImg: require("@/assets/images/noorder.png"),
            loading: false,
            loadingSerive: null,

            userName: '',
            userNameRes: '',
            userList: [],
            // 分页
            currentPage: 1,
            pageSize: 20,
            total: 0,

            // 新增用户
            showDialog: false,
            user_id: '',
            // 交易所选择
            showExchangeDialog: false,
            popWeb: getWebInfo('web'),
            popExchange: getWebInfo('exchange'),
        }
    },
    created(){
        this.getList();
    },
    methods: {
        // 新增用户弹窗
        openAddDialog(){
            this.showDialog=true;
        },
        // 新增用户
        addUser(){
            if(!this.popWeb){
                this.$message({
                    message: this.$t("tip.selectExchange"),
                    type: "error",
                    center: true,
                    customClass: "shotMsg",
                });
            }else if(!this.user_id){
                this.$message({
                    message: this.$t("tip.usernameEmpty"),
                    type: "error",
                    center: true,
                    customClass: "shotMsg",
                });
            }else{
                this.loadingSerive = Loading.service({
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)',
                    customClass: 'apploading',
                });
                createRiskUser({
                    account: this.account,
                    user_id: this.user_id,
                    web: this.popWeb,
                }).then(res=>{
                    this.showDialog=false;
                    this.$message({
                        message: this.$t("tip.addSuc"),
                        type: "success",
                        center: true,
                        customClass: "shotMsg",
                    });
                    this.loadingSerive.close();

                    // 需要本地缓存popExchange、popWeb
                    setWebInfo({
                        exchange: this.popExchange,
                        web: this.popWeb,
                    });

                    this.getList();
                    // 弹窗数据初始化
                    this.user_id='';
                }).catch(err=>{
                    this.loadingSerive.close();
                })
            }
        },

        // 搜索
        searchUser(){
            this.userNameRes=this.userName;
            this.currentPage=1;
            this.getList();
        },
        getList(){
            this.loading=true;
            riskUserList({
                account: this.account,
                user_id: this.userNameRes,
                page: this.currentPage,
                limit: this.pageSize,
            }).then(res=>{
                // console.log(res);
                this.userList=res.list;
                this.total=res.count;
                this.loading=false;
            }).catch(err=>{
                this.userList=[];
                this.total=0;
                this.loading=false;
            })
        },

        handleDelete(row){
            this.$confirm(this.$t('tip.delTip'), this.$t('tip.tips'), {
                confirmButtonText: this.$t('common.confirm'),
                cancelButtonText: this.$t('common.cancel'),
                center: true,
                customClass: 'deleteOk',
                showClose:false,
            }).then(() => {
                removeRiskUser({
                    account: this.account,
                    web: row.web,
                    user_id: row.user_id,
                }).then(res=>{
                    this.$message({
                        message: this.$t("tip.delSuc"),
                        type: "success",
                        center: true,
                        customClass: "shotMsg",
                    });
                    this.getList();
                })
            }).catch(() => {});
        },
        handleView(row){
            // console.log(row);
            this.$router.push({
                path: '/userRiskDetail',
                query: {
                    userName: row.user_id,
                }
            })
        },

        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.currentPage = 1;
            this.getList();
        },

        // 交易所
        closeExchange() {
            this.showExchangeDialog = false;
        },
        handleSelect(data) {
            this.popExchange = data.text;
            this.popWeb = data.web;
            this.showExchangeDialog = false;
        },
    },
}
</script>